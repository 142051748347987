/**
 * Parse the date object to string
 * @param date {Date} - The date object
 * @returns {string} - The formatted date string
 */
export function parseDateObjectToString(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
}

/**
 * Round a number to a certain number of decimal places
 * @param number {number} - The numeric value to be rounded
 * @param places {number} - The number of decimal places
 * @returns {number} - The rounded number
 */
export function roundNumber(number, places) {
    return Number(number.toFixed(places));
}

/**
 * Generate a random string of a certain length
 * @param length {number} - The length of the string
 * @returns {string} - The random string
 */
export function generateString(length) {
    return Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1);
}

/**
 *
 * @param element
 * @param event
 */
export function observeEvent(element, event) {
    const MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

    const observer = new MutationObserver((mutations) => {
        if (mutations[0].attributeName === 'value') {
            // Manually create an on-change event
            const e = new Event(event);

            // Manually dispatch event
            element.dispatchEvent(e);
        }
    });

    observer.observe(element, {
        attributes: true
    });
}

/**
 * Bind an event listener to a parent element that delegates the event to a child element matching the selector.
 *
 * This function allows you to attach an event listener to a parent element and delegate the event handling
 * to a child element that matches the specified selector. This is particularly useful for handling events
 * on dynamically added elements.
 *
 * @param {HTMLElement|Document} parent - The parent element to which the event listener is attached.
 * @param {string} event - The event type to listen for (e.g., 'click', 'change').
 * @param {string} selector - The CSS selector to match the child elements that should handle the event.
 * @param {Function} handler - The event handler function to be called when the event is triggered.
 *
 * @example
 * // HTML structure:
 * <div id="parent">
 *   <button class="child">Click me</button>
 * </div>
 *
 * // JavaScript:
 * const parentElement = document.getElementById('parent');
 * bindEventListener(parentElement, 'click', '.child', function(event) {
 *   console.log('Button clicked:', this);
 * });
 */
export function bindEventListener(parent, event, selector, handler) {
    parent.addEventListener(event, function (e) {
        if (e.target.matches(selector + ', ' + selector + ' *')) {
            handler.apply(e.target.closest(selector), arguments);
        }
    }, false);
}

/**
 * Delay event listeners by a certain amount of time.
 *
 * This function creates a debounced version of the provided function, which delays its execution
 * until after a specified amount of time has passed since the last time it was invoked. This is useful
 * for limiting the rate at which a function is executed, such as handling resize or scroll events.
 *
 * @param {Function} func - The function to be delayed.
 * @param {number} [timeout=200] - The amount of time to delay the function by in milliseconds.
 * @returns {Function} - The debounced function.
 *
 * @example
 * // Usage example:
 * const debouncedResizeHandler = debounce(() => {
 *   console.log('Resize event handled');
 * }, 300);
 *
 * window.addEventListener('resize', debouncedResizeHandler);
 */
export function debounce(func, timeout = 200) {
    let timeout_id;

    return function () {
        const scope = this, args = arguments;

        clearTimeout(timeout_id);

        timeout_id = setTimeout(function () {
            func.apply(scope, Array.prototype.slice.call(args));
        }, timeout);
    };
}

/**
 * Copy a string to the clipboard using the Clipboard API.
 *
 * @param {string} text - The string to be copied to the clipboard.
 * @param {Object} [callbacks] - Optional callbacks for success and error handling.
 * @param {Function} [callbacks.onSuccess] - The callback function to be executed on successful copy.
 * @param {Function} [callbacks.onError] - The callback function to be executed on copy failure.
 */
export function copyToClipboard(text, {onSuccess = null, onError = null} = {}) {
    navigator.clipboard.writeText(text).then(() => {
        console.log('Text copied to clipboard');
        if(onSuccess) {
            onSuccess();
        }
    }).catch(err => {
        console.error('Failed to copy text: ', err);
        if(onError) {
            onError();
        }
    });
}

/**
 * =====================================================================================================================
 * Request helper functions
 * =====================================================================================================================
 */

/**
 * Serialize object
 *
 * @param params
 * @param prefix
 * @returns {string}
 */
export function serializeQuery(params, prefix = null) {
    const query = Object.keys(params).map((key) => {
        const value = params[key];

        if (params.constructor === Array) {
            key = `${prefix}[]`;
        }
        else if (params.constructor === Object) {
            key = (prefix ? `${prefix}[${key}]` : key);
        }

        if (typeof value === 'object') {
            return serializeQuery(value, key);
        }
        else {
            return `${key}=${encodeURIComponent(value)}`;
        }
    });

    return [].concat.apply([], query).join('&');
}

/**
 * Serialize nested object
 *
 * @param params
 * @param prefix
 * @returns {string}
 */
export function serializeNestedQuery(params, prefix = null) {
    /**
     * Serialize a single key-value pair.
     * @param {string} key - The key to serialize.
     * @param {*} value - The value to serialize.
     * @param {string|null} prefix - The prefix for nested parameters.
     * @returns {string} - The serialized key-value pair.
     */
    function serializePair(key, value, prefix) {
        const prefixedKey = prefix ? `${prefix}[${key}]` : key;

        // Recursively serialize nested objects and arrays
        if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
            return serializeNestedQuery(value, prefixedKey);
        }
        else if (value !== null && Array.isArray(value)) {
            return value.map((item, index) => serializeNestedQuery(item, `${prefixedKey}[${index}]`)).join('&');
        }
        else {
            return `${encodeURIComponent(prefixedKey)}=${encodeURIComponent(value)}`;
        }
    }

    // Map each key-value pair to its serialized form and join them with '&'
    const query = Object.keys(params).map(key => serializePair(key, params[key], prefix));

    return query.join('&');
}

/**
 * Make an HTTP request.
 *
 * This function handles HTTP requests using the Fetch API. It supports GET, POST, PUT, and DELETE methods,
 * and can handle both JSON and text responses.
 *
 * It also supports progress events for file uploads, but does this by delegating to the `xmlHttpRequest` function
 * and NOT using the Fetch API.
 *
 * @param {string} url - The URL to request.
 * @param {Object|FormData|string} [params={}] - The parameters to include in the request.
 * @param {string} dataType - The expected response data type ('json' or 'text').
 * @param {string} [method='GET'] - The HTTP method to use.
 * @param {Object} [options={}] - Additional options for the request.
 * @param {function} [options.onProgress=null] - The progress event handler function.
 * @param {boolean} [options.withCredentials=false] - Whether to include credentials in the request.
 *
 * @returns {Promise<Object|string>} - A promise that resolves with the response data.
 *
 * @throws {Object} - An error object containing statusCode, statusText, and response.
 */
export function request(
    url, params = {}, dataType, method = 'GET',
    {
        onProgress = null,
        withCredentials = false,
    } = {}
) {
    let options = {
        method
    };

    // Use XMLHttpRequest for POST requests with FormData and progress
    if(params instanceof FormData && onProgress && method.toUpperCase() === 'POST') {
        return xmlHttpRequest(url, params, dataType, method, {onProgress, withCredentials});
    }

    if (method.toUpperCase() === 'GET') {
        // Serialize request data
        url += '?' + new URLSearchParams(params).toString();

        if(dataType.toUpperCase() === 'JSON') {
            // Let the server know we expect JSON as a response
            options.headers = {
                'Accept': 'application/json',
            };
        }
    } else {
        if (params instanceof FormData) {
            // Pass form data
            options.body = params;

            options.headers = {
                'X-Requested-With': 'XMLHttpRequest',
            };
        } else {
            // Stringify and pass data
            options.body = JSON.stringify(params);

            // CSRF protection
            options.headers = {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
            };
        }
    }

    if(withCredentials) {
        options.credentials = 'include';
    }

    return fetch(url, options).then(async (response) => {
        if (response.status === 200) {
            return await (dataType === 'json') ? response.json() : response.text();
        }

        throw {
            'statusCode': response.status,
            'statusText': response.statusText,
            'response': await response.json()
        };
    });
}

/**
 * Make an HTTP POST request via XMLHttpRequest (for progress events).
 *
 * This function is designed to handle HTTP POST requests using the XMLHttpRequest object,
 * which allows for tracking the progress of the request. It is particularly useful for
 * file uploads where progress feedback is needed.
 *
 * @param {string} url - The URL to which the request is sent.
 * @param {FormData} params - The parameters to include in the request, typically form data.
 * @param {string} dataType - The expected response data type ('json' or 'text').
 * @param {string} [method='POST'] - The HTTP method to use, default is 'POST'.
 * @param {Object} [options={}] - Additional options for the request.
 * @param {function} [options.onProgress=null] - The progress event handler function.
 * @param {boolean} [options.withCredentials=false] - Whether to include credentials in the request.
 *
 * @returns {Promise<Object|string>} - A promise that resolves with the response data.
 *
 * @throws {Object} - An error object containing statusCode, statusText, and response.
 */
function xmlHttpRequest(
    url, params, dataType, method = 'POST',
    {
        onProgress = null,
        withCredentials = false
    } = {}
) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.withCredentials = withCredentials;
        xhr.open('POST', url, true);
        xhr.setRequestHeader('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content);

        if (onProgress) {
            xhr.upload.addEventListener('progress', onProgress, false);
        }

        xhr.onload = function() {
            if ([200, 201].includes(xhr.status)) {
                resolve(dataType === 'json' ? JSON.parse(xhr.responseText) : xhr.responseText);
            } else {
                console.error('xhr.status !== 200', xhr.responseText, xhr.response);

                let response = {}

                if(xhr.responseText) {
                    try {
                        response = dataType === 'json' ? JSON.parse(xhr.responseText) : xhr.responseText
                    } catch (e) {
                        response = 'Invalid JSON response'
                    }
                }

                reject({
                    statusCode: xhr.status,
                    statusText: xhr.statusText,
                    response: response,
                });
            }
        };

        xhr.onerror = function() {
            console.error('xhr.onerror', xhr.responseText, xhr.response);

            let response = {}

            if(xhr.responseText) {
                try {
                    response = dataType === 'json' ? JSON.parse(xhr.responseText) : xhr.responseText
                } catch (e) {
                    response = 'Invalid JSON response'
                }
            }

            reject({
                statusCode: xhr.status,
                statusText: xhr.statusText,
                response: response,
            });
        };

        try {
            xhr.send(params);
        } catch (e) {
            console.error('xhr.send error', e);
            reject({
                statusCode: 500,
                statusText: 'Internal Server Error',
                response: {}
            });
        }
    });
}

/**
 * Make a GET request.
 * @param {string} url - The URL to request.
 * @param {Object|FormData|String} [params={}] - The parameters to include in the request.
 * @param {string} [dataType='json'] - The expected response data type ('json' or 'text').
 * @param {Object} [options={}] - Additional options for the request.
 * @param {boolean} [options.withCredentials=false] - Whether to include credentials in the request.
 * @returns {Promise<Object|string>} - The response data.
 */
export function get(
    url, params, dataType = 'json',
    {
        withCredentials = false,
    } = {}
) {
    return request(url, params, dataType, 'GET', {withCredentials});
}

/**
 * Make a POST request.
 * @param {string} url - The URL to request.
 * @param {Object|FormData|String} [params={}] - The parameters to include in the request.
 * @param {string} [dataType='json'] - The expected response data type ('json' or 'text').
 * @param {Object} [options={}] - Additional options for the request.
 * @param {function} [options.onProgress=null] - The progress event handler function.
 * @param {boolean} [options.withCredentials=false] - Whether to include credentials in the request.
 * @returns {Promise<Object|string>} - The response data.
 */
export function post(
    url, params, dataType = 'json',
    {
        onProgress = null,
        withCredentials = false,
    } = {}
) {
    return request(url, params, dataType, 'POST', {onProgress, withCredentials});
}

/**
 * Make a PUT request.
 * @param {string} url - The URL to request.
 * @param {Object|FormData|String} [params={}] - The parameters to include in the request.
 * @param {string} [dataType='json'] - The expected response data type ('json' or 'text').
 * @param {Object} [options={}] - Additional options for the request.
 * @param {boolean} [options.withCredentials=false] - Whether to include credentials in the request.
 * @returns {Promise<Object|string>} - The response data.
 */
export function put(
    url, params, dataType = 'json',
    {
        withCredentials = false
    } = {}
) {
    return request(url, params, dataType, 'PUT', {withCredentials});
}

/**
 * Make a DELETE request.
 * @param {string} url - The URL to request.
 * @param {Object|FormData|String} [params={}] - The parameters to include in the request.
 * @param {string} [dataType='json'] - The expected response data type ('json' or 'text').
 * @param {Object} [options={}] - Additional options for the request.
 * @param {boolean} [options.withCredentials=false] - Whether to include credentials in the request.
 * @returns {Promise<Object|string>} - The response data.
 */
export function destroy(
    url, params, dataType = 'json',
    {
        withCredentials = false
    } = {}
) {
    return request(url, params, dataType, 'DELETE', {withCredentials});
}

/**
 * Check if an element has a data attribute.
 * @param {HTMLElement} element - The element to get the data attribute value from.
 * @param {string} attribute - The name of the data attribute to get.
 * @returns {boolean} - Whether the element has the data attribute.
 */
export function hasDataAttribute(element, attribute) {
    // Convert the attribute from camelCase to kebab-case
    const kebabCaseAttribute = attribute.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    return element.hasAttribute(`data-${kebabCaseAttribute}`);
}

/**
 * Get the value of a data attribute on an element.
 * @param {HTMLElement} element - The element to get the data attribute value from.
 * @param {string} attribute - The name of the data attribute to get.
 * @returns {string|null} - The value of the data attribute or null if the attribute is not present.
 */
export function getDataAttributeValue(element, attribute) {
    // Check if the element has the data attribute, return null if not
    if(!hasDataAttribute(element, attribute)) {
        return null;
    }

    // Convert the attribute from camelCase to kebab-case
    const kebabCaseAttribute = attribute.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    return element.getAttribute(`data-${kebabCaseAttribute}`);
}
