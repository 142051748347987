// SweetAlert2 (https://sweetalert2.github.io/)
import Swal from 'sweetalert2';
import {destroy, getDataAttributeValue, hasDataAttribute, post, put} from "../helper.js";

/**
 * @var {Swal} defaultSwal - A pre-configured Swal instance for general use
 */
export const defaultSwal = Swal.mixin({
    customClass: {
        confirmButton: "swal2-styled btn btn-primary",
        cancelButton: "swal2-styled btn btn-light"
    },
    buttonsStyling: true
});

/**
 * @var {Swal} errorSwal - A pre-configured Swal instance specifically for error messages
 */
export const errorSwal = Swal.mixin({
    customClass: {
        confirmButton: "swal2-styled btn btn-danger",
        cancelButton: "swal2-styled btn btn-light"
    },
    buttonsStyling: true,
    icon: 'error',
    showCancelButton: false,
    confirmButtonText: 'OK',
});

/**
 * Handle the fetch API response after the user clicked an action button
 * @param {Object} response - The fetch API response.
 * @param {Object} [options={}] - Additional options for the response handling.
 * @param {boolean} [options.reload=true] - Whether to reload the page after the action is completed (optional).
 * @param {(function(Object|string, {errorTitle: (string|null), errorText: (string|null), errorCallback: (function(Object|string):void)|null}):void)|null} [options.successCallback=null] - The callback to execute on success (optional).
 * @param {string|null} [options.errorTitle=null] - The title of the error dialog (optional).
 * @param {string|null} [options.errorText=null] - The text of the error dialog (optional).
 * @param {(function(Object|string):void)|null} [options.errorCallback=null] - The callback to execute on error (optional).
 *
 * TODO: Add support for using response error message as swal errorText
 */
export function handleActionResponse(
    response,
    {
        reload = true,
        successCallback = null,
        errorTitle = null,
        errorText = null,
        errorCallback = null,
    } = {}
) {
    // Execute the success callback if provided and return (skip the default handling)
    if (successCallback) {
        successCallback(response, {
            errorTitle,
            errorText,
            errorCallback,
        });
        return;
    }

    // Reload the page if the response status is 'OK'
    if (response.status === 'OK') {
        if(reload === true) {
            location.reload();
        }
    } else {
        console.error(response);
        if (errorCallback) {
            errorCallback(response);
            return;
        }
        errorSwal.fire({
            icon: 'error',
            title: errorTitle || 'Actie mislukt!',
            text: errorText || 'Er is een fout opgetreden bij het uitvoeren van de actie.'
        });
    }
}

/**
 * Handle an error after the user clicked an action button if something went wrong
 * @param {Object} error - The error object.
 * @param {Object} [options={}] - Additional options for the response handling.
 * @param {string|null} [options.errorTitle=null] - The title of the error dialog (optional).
 * @param {string|null} [options.errorText=null] - The text of the error dialog (optional).
 * @param {(function(Object|string):void)|null} [options.errorCallback=null] - The callback to execute on error (optional).
 */
export function handleActionError(
    error,
    {
        errorTitle = null,
        errorText = null,
        errorCallback = null,
    } = {}
) {
    console.error(error);

    // Execute the error callback if provided and return (skip the default handling)
    if (errorCallback) {
        errorCallback(error);
        return;
    }
    errorSwal.fire({
        icon: 'error',
        title: errorTitle || 'Actie mislukt!',
        text: errorText || 'Er is een fout opgetreden bij het uitvoeren van de actie.'
    });
}

/**
 * Confirm
 * @param {string} url - The URL to send the request to.
 * @param {string} title - The title of the confirmation dialog.
 * @param {string} message - The message of the confirmation dialog.
 * @param {Object} [options={}] - Additional options for the response handling.
 * @param {boolean} [options.reload=true] - Whether to reload the page after the action is completed (optional).
 * @param {(function(Object|string, {errorTitle: (string|null), errorText: (string|null), errorCallback: (function(Object|string):void)|null}):void)|null} [options.successCallback=null] - The callback to execute on success (optional).
 * @param {string|null} [options.errorTitle=null] - The title of the error dialog (optional).
 * @param {string|null} [options.errorText=null] - The text of the error dialog (optional).
 * @param {(function(Object|string):void)|null} [options.errorCallback=null] - The callback to execute on error (optional).
 * @param {string|null} [options.confirmButtonText=null] - The text of the confirm button (optional).
 * @param {string|null} [options.cancelButtonText=null] - The text of the cancel button (optional).
 *
 * TODO: Extract the FETCH API request logic to a separate function to make this function more reusable
 *  and have no dependency on the helper.js file.
 */
export function confirmAction(
    url, title, message,
    {
        requestType = 'POST',
        reload = true,
        successCallback = null,
        errorTitle = null,
        errorText = null,
        errorCallback = null,
        confirmButtonText = null,
        cancelButtonText = null
    } = {}
) {
    defaultSwal.fire({
        title: title,
        text: message,
        icon: 'warning',
        showCancelButton: true,
        // confirmButtonColor: '#FF9A01',
        confirmButtonText: confirmButtonText || 'Bevestigen',
        cancelButtonText: cancelButtonText || 'Annuleren'
    }).then((result) => {
        if (result.value) {
            // Determine the request type (POST or PUT)
            const request = requestType.toUpperCase() === 'PUT' ? put : post;

            // Send the request and handle the response
            request(url).then((response) => handleActionResponse(response, {
                reload,
                successCallback,
                errorTitle,
                errorText,
                errorCallback,
            })).catch((error) => handleActionError(error, {
                errorTitle,
                errorText,
                errorCallback
            }));
        }
    });
}

/**
 * Restore a record
 * @param {string} url - The URL to send the request to.
 * @param {string} title - The title of the confirmation dialog.
 * @param {string} message - The message of the confirmation dialog.
 * @param {Object} [options={}] - Additional options for the confirmation dialog.
 * @param {(function(Object|string, {errorTitle: (string|null), errorText: (string|null), errorCallback: (function(Object|string):void)|null}):void)|null} [options.successCallback=null] - The callback to execute on success (optional).
 * @param {(function(Object|string):void)|null} [options.errorCallback=null] - The callback to execute on error (optional).
 * @param {string|null} [options.confirmButtonText=null] - The text of the confirm button (optional).
 * @param {string|null} [options.cancelButtonText=null] - The text of the cancel button (optional).
 *
 * TODO: Extract the FETCH API request logic to a separate function to make this function more reusable
 */
export function restoreRecord(
    url, title, message,
    {
        successCallback = null,
        errorCallback = null,
        confirmButtonText = null,
        cancelButtonText = null
    } = {}
) {
    defaultSwal.fire({
        title: title,
        text: message,
        icon: 'warning',
        showCancelButton: true,
        // confirmButtonColor: '#FF9A01',
        confirmButtonText: confirmButtonText || 'Terugzetten',
        cancelButtonText: cancelButtonText || 'Annuleren'
    }).then((result) => {
        if (result.value) {
            put(url)
                .then((response) => handleActionResponse(response, {
                    successCallback, errorCallback
                }))
                .catch((error) => handleActionError(error, {
                    errorCallback
                }));
        }
    });
}

/**
 * Delete a record
 * @param {string} url - The URL to send the request to.
 * @param {string} title - The title of the confirmation dialog.
 * @param {string} message - The message of the confirmation dialog.
 * @param {Object} [options={}] - Additional options for the response handling.
 * @param {(function(Object|string, {errorTitle: (string|null), errorText: (string|null), errorCallback: (function(Object|string):void)|null}):void)|null} [options.successCallback=null] - The callback to execute on success (optional).
 * @param {string|null} [options.errorTitle=null] - The title of the error dialog (optional).
 * @param {string|null} [options.errorText=null] - The text of the error dialog (optional).
 * @param {(function(Object|string):void)|null} [options.errorCallback=null] - The callback to execute on error (optional).
 * @param {string|null} [options.confirmButtonText=null] - The text of the confirm button (optional).
 * @param {string|null} [options.cancelButtonText=null] - The text of the cancel button (optional).
 *
 * TODO: Extract the FETCH API request logic to a separate function to make this function more reusable
 *  and have no dependency on the helper.js file.
 */
export function deleteRecord(
    url, title, message,
    {
        successCallback = null,
        errorTitle = null,
        errorText = null,
        errorCallback = null,
        confirmButtonText = null,
        cancelButtonText = null
    } = {}
) {
    defaultSwal.fire({
        title: title,
        text: message,
        icon: 'warning',
        showCancelButton: true,
        // confirmButtonColor: '#FF9A01',
        confirmButtonText: confirmButtonText || 'Ja, Verwijderen',
        cancelButtonText: cancelButtonText || 'Annuleren'
    }).then((result) => {
        if (result.value) {
            destroy(url).then((response) => handleActionResponse(response, {
                successCallback: successCallback,
                errorTitle: errorTitle,
                errorText: errorText,
                errorCallback: errorCallback,
            })).catch((error) => handleActionError(error, {
                errorTitle: errorTitle,
                errorText: errorText,
                errorCallback: errorCallback,
            }));
        }
    });
}

/**
 * Extracts data attributes from an HTML element and returns them as an options object.
 *
 * This function reads specific data attributes from the provided HTML element and constructs
 * an options object with corresponding properties. The data attributes and their corresponding
 * properties in the options object are as follows:
 * - `data-request-type`: Sets the `request-type` property to the attribute value.
 * - `data-reload`: Sets the `reload` property to `true` or `false` based on the attribute value.
 * - `data-error-title`: Sets the `errorTitle` property to the attribute value.
 * - `data-error-text`: Sets the `errorText` property to the attribute value.
 * - `data-confirm-button-text`: Sets the `confirmButtonText` property to the attribute value.
 * - `data-cancel-button-text`: Sets the `cancelButtonText` property to the attribute value.
 *
 * @param {HTMLElement} element - The HTML element from which to extract data attributes.
 * @returns {Object} An options object containing the extracted data attributes.
 */
export function getDataAttributeOptions(element) {
    const options = {};

    // List of attributes to extract from the element
    const attributes = [
        { name: 'requestType', type: 'string' },
        { name: 'reload', type: 'boolean' },
        { name: 'errorTitle', type: 'string' },
        { name: 'errorText', type: 'string' },
        { name: 'confirmButtonText', type: 'string' },
        { name: 'cancelButtonText', type: 'string' }
    ];

    // Extract the attributes from the element and put them in the options object
    attributes.forEach(attr => {
        const value = getDataAttributeValue(element, attr.name);
        if (value !== null) {
            options[attr.name] = attr.type === 'boolean' ? value === 'true' : value;
        }
    });

    return options;
}
